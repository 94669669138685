import React, { useState } from 'react'
import { navigate } from 'gatsby'
import { makeStyles } from "@material-ui/core/styles";
import productStyle from 'assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.jsx'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import Radio from '@material-ui/core/Radio'
import Button from 'components/CustomButtons/Button.jsx'
import Layout from '../components/layout'
import useAuth from '../firebase/useAuth'
import firebase from '../firebase'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #232323;
  min-height: 100vh;
  padding: 0 5%;
`

const RadioWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 5% auto;
`

const ButtonWrap = styled.div`
  display: flex;
  padding: 5% 5%;
  flex-direction: column;
  width: 40%;
  margin: 0 auto;
  @media (max-width: 1000px) {
    width: 45%;
  }
  @media (max-width: 800px) {
    width: 50%;
  }
  @media (max-width: 700px) {
    width: 50%;
  }
`
const ButtonText = styled.p`
  font-size: 1.5vh;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  @media (max-width: 400px) {
    font-size: 1vh;
  }
`

const Heading = styled.h1`
  font-size: 6vw;
  font-weight: bold;
  padding: 0 5%;
  color: white;
`

const useStyles = makeStyles(productStyle);

export default function NewUser() {
  const [role, setRole] = useState('')
  const user = useAuth()
  const classes = useStyles();

  const SaveNewProfile = async () => {
    if (role && user) {
      const userRef = firebase.db.collection('users').doc(user.email)
      const clientRef = firebase.db.collection('client').doc(user.email)
      const freeLancerRef = firebase.db.collection('freelancer').doc(user.email)
      if (role === 'Client') {
        await userRef.set({ role }, { merge: true })
        userRef.get().then(async doc => {
          if (doc.exists) {
            const { username, id, email } = doc.data()

            await clientRef.set(
              {
                username,
                id,
                email,
                role,
                linkedIn: '',
                gitHub: '',
                languageTest: '',
                techTest: '',
                skillSets: '',
                publish: '',
              },
              { merge: true }
            )
          }
        })

        navigate('/editprofile')
      } else if (role === 'Freelancer') {
        await userRef.set({ role }, { merge: true })
        userRef.get().then(async doc => {
          if (doc.exists) {
            const { username, id, email } = doc.data()
            await freeLancerRef.set(
              { username, id, email, role },
              { merge: true }
            )
          }
        })
        navigate('/editprofile')
      }
    }
  }

  return (
    <Layout>
      <Wrapper>
        <Heading>How would you like to access Flexatal?</Heading>

        <RadioWrap>
          <FormControlLabel
            value="start"
            control={
              <Radio
                checked={role === 'Client'}
                onChange={e => setRole(e.target.value)}
                value="Client"
                name="radio button enabled"
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            label="Client"
            labelPlacement="start"
          />
          <FormControlLabel
            value="start"
            control={
              <Radio
                checked={role === 'Freelancer'}
                onChange={e => setRole(e.target.value)}
                value="Freelancer"
                name="Freelancer"
                aria-label="A"
                icon={<FiberManualRecord className={classes.radioUnchecked} />}
                checkedIcon={
                  <FiberManualRecord className={classes.radioChecked} />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot,
                }}
              />
            }
            label="Freelancer"
            labelPlacement="start"
          />
        </RadioWrap>

        <ButtonWrap>
          <Button
            round
            disabled={!role ? true : false}
            color="rose"
            onClick={() => SaveNewProfile()}
          >
            <ButtonText>Lets Flexatal</ButtonText>
          </Button>
        </ButtonWrap>
      </Wrapper>
    </Layout>
  )
}

